<template>
  <base-modal-ex
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="60"
    has-modal-card>
    <header class="modal-card-head">
      <p
        class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span
          v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem;">
          <i
            class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <span>e-MTA Registration</span>
      </p>
    </header>
    <section class="modal-card-body">
      <!-- <slot name="text-content"></slot> -->
      <emta-registration v-model="emtaInfo" />
    </section>
    <footer class="modal-card-foot">
      <div :style="`width: 100%; display: flex; justify-content: space-between;`">
        <div style="align-self: flex-start;" />
        <div style="align-self: flex-end;">
          <button
            class="button is-primary tooltip"
            @click="register()"
            data-tooltip="Register"
            :disabled="!requireFields">
            <span class="icon">
              <i class="mdi mdi-account-plus mdi-18px" />
            </span>
            <span>Register</span>
          </button>
          <button
            class="button tooltip"
            @click="cancel()"
            data-tooltip="Cancel">
            <span class="icon">
              <i class="mdi mdi-close mdi-18px" />
            </span>
            <span>Cancel</span>
          </button>
        </div>
      </div>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import EmtaRegistration from './EmtaRegistration'
import EmtaService from './EmtaService'

export default {
  name: 'EmtaRegistrationModal',
  components: {
    BaseModalEx,
    EmtaRegistration
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'warning'
    },
    iconName: {
      type: String,
      default: 'mdi-alert-decagram'
    }
  },
  data() {
    return {
      isActive: this.active || false,
      emtaInfo: {
        businessName: '',
        contactName: '',
        address1: '',
        address2: '',
        city: '',
        suburb: '',
        postalCode: '',
        state: '',
        phone1: '',
        phone2: '',
        email: ''
      }
    }
  },
  computed: {
    requireFields() {
      return this.emtaInfo.businessName !== '' && this.emtaInfo.city !== '' && this.emtaInfo.state !== '' && this.emtaInfo.postalCode !== ''
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    }
  },
  created() {
    this.emtaInfo.businessName = this.$userInfo.companyName
  },
  mounted() {},
  methods: {
    async isNewCrashListAvailable(version) {
      const isNew = await EmtaService.isNewCrashListAvailable(version)
      console.log(isNew)
    },
    async registerUser() {
      const result = await EmtaService.registerUser(this.emtaInfo)
      console.log(result)
    },
    async register() {
      this.$showSpinner('Registering')
      await this.registerUser()
      // await this.isNewCrashListAvailable(6215)
      this.$hideSpinner()
      // this.isActive = false
      this.$emit('register')
    },
    cancel() {
      // this.isActive = false
      this.$emit('cancel')
    }
  }
}
</script>
