import FqApi from '@/services/fq-api'

export default {
  async isNewCrashListAvailable(version) {
    const url = `/emta/isnewcrashlist/${version}`
    const result = await FqApi.get(url)
    return result.data
  },
  async registerUser(companyRecord) {
    const url = '/emta/registeruser'
    const result = await FqApi.post(url, companyRecord)
    return result.data
  },
  async isEmtaRegistered() {
    const url = '/emta/isemtaregistered'
    const result = await FqApi.get(url)
    return result.data
  },
  async getMakeList() {
    const url = '/emta/makelist'
    const result = await FqApi.get(url)
    return result.data
  },
  async getModelList(makeId, year) {
    const url = `/emta/modellist/${makeId}/${year}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getYearList(makeId, modelId) {
    const url = `/emta/yearlist/${makeId}/${modelId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getModelVariantList(makeId, modelId, year) {
    const url = `/emta/variantlist/${makeId}/${modelId}/${year}`
    const result = await FqApi.get(url)
    return result.data
  },
  async updateEmtaCatalogue(filter) {
    const url = '/emta/updatecatalogue'
    const result = await FqApi.post(url, filter)
    return result
  }
}
