<template>
  <base-modal-ex
    :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="30"
    has-modal-card>
    <header class="modal-card-head">
      <p
        class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span
          v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem;">
          <i
            class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <span>e-MTA Vehicle Selection</span>
      </p>
    </header>
    <section class="modal-card-body">
      <!-- <slot name="text-content"></slot> -->
      <emta-vehicle-selection
        :make="make"
        v-model="innerValue"
        :model="model"
        :year="year" />
    </section>
    <footer class="modal-card-foot">
      <div :style="`width: 100%; display: flex; justify-content: space-between;`">
        <div style="align-self: flex-start;" />
        <div style="align-self: flex-end;">
          <button
            class="button is-primary tooltip"
            @click="select()"
            data-tooltip="Select">
            <span class="icon">
              <i class="mdi mdi-select mdi-18px" />
            </span>
            <span>Select</span>
          </button>
          <button
            class="button tooltip"
            @click="cancel()"
            data-tooltip="Cancel">
            <span class="icon">
              <i class="mdi mdi-close mdi-18px" />
            </span>
            <span>Cancel</span>
          </button>
        </div>
      </div>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import EmtaVehicleSelection from './EmtaVehicleSelection'
// import EmtaService from './EmtaService'

export default {
  name: 'EmtaVehicleSelectionModal',
  components: {
    BaseModalEx,
    EmtaVehicleSelection
  },
  props: {
    value: null,
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'warning'
    },
    iconName: {
      type: String,
      default: 'mdi-alert-decagram'
    },
    make: {
      type: String,
      default: ''
    },
    model: {
      type: String,
      default: ''
    },
    year: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isActive: this.active || false,
      innerValue: null
    }
  },
  computed: {},
  watch: {
    active(value) {
      this.isActive = value
    },
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    this.innerValue = this.value
  },
  mounted() {},
  methods: {
    async isNewCrashListAvailable(version) {
      // const isNew = await EmtaService.isNewCrashListAvailable(version)
      // console.log(isNew)
    },
    async select() {
      this.$showSpinner()
      this.$hideSpinner()
      this.$emit('select')
    },
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>
