<template>
  <div>
    <div class="field">
      <label class="label">Make</label>
      <div class="control">
        <div
          class="select"
          :class="{ 'is-loading' : isLoadingMakeList}">
          <select v-model="emtaVehicle.makeId">
            <option
              v-for="(m) in makeList"
              :key="m.makeID"
              :value="m.makeID">{{ m.makeName }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="field">
      <label class="label">Model</label>
      <div class="control">
        <div
          class="select"
          :class="{ 'is-loading' : isLoadingModelList}">
          <select v-model="emtaVehicle.modelId">
            <option
              v-for="(m) in modelList"
              :key="m.modelID"
              :value="m.modelID">{{ m.modelName }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="field">
      <label class="label">Year</label>
      <div class="control">
        <div
          class="select"
          :class="{ 'is-loading' : isLoadingYearList}">
          <select v-model="emtaVehicle.year">
            <option
              v-for="(y) in yearList"
              :key="y"
              :value="y">{{ y }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="field">
      <label class="label">Variant</label>
      <div class="control">
        <div
          class="select"
          :class="{ 'is-loading' : isLoadingModelVariantList}">
          <select v-model="selectedVariant">
            <option
              v-for="(variant) in modelVariantList"
              :key="variant.variantId"
              :value="variant">{{ variant.variantName }} ({{ variant.variantTransmission }})</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FocusInserted } from '@/components/directives'
import EmtaService from './EmtaService'
import _orderBy from 'lodash/orderBy'

export default {
  name: 'EmtaVehicleSelection',
  directives: { FocusInserted },
  components: {},
  props: {
    value: null,
    make: {
      type: String,
      default: ''
    },
    model: {
      type: String,
      default: ''
    },
    year: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      makeList: null,
      isLoadingMakeList: false,
      modelList: null,
      isLoadingModelList: false,
      yearList: null,
      isLoadingYearList: false,
      modelVariantList: null,
      isLoadingModelVariantList: false,
      selectedVariant: null,
      emtaVehicle: {
        makeId: '',
        modelId: '',
        year: null,
        variantId: '',
        nvic: ''
      }
    }
  },
  computed: {},
  watch: {
    emtaVehicle: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    'emtaVehicle.makeId': function(newVal, oldVal) {
      if (newVal) {
        this.getModelList()
      } else {
        // this.emtaVehicle.modelId = ''
        // this.emtaVehicle.variantId = ''
        // this.emtaVehicle.year = 0
        // this.modelList = null
        // this.yearList = null
        // this.modelVariantList = null
      }
    },
    'emtaVehicle.modelId': function(newVal, oldVal) {
      if (newVal) {
        this.getYearList()
        this.modelVariantList = null
      } else {
        this.yearList = null
        this.modelVariantList = null
      }
    },
    'emtaVehicle.year': function(newVal, oldVal) {
      if (newVal) {
        this.getModelVariantList()
      } else {
        this.modelVariantList = null
      }
    },
    selectedVariant: function(newVal, oldVal) {
      if (newVal) {
        this.emtaVehicle.variantId = newVal.variantId
        this.emtaVehicle.nvic = newVal.nvic
      } else {
        this.emtaVehicle.variantId = ''
        this.emtaVehicle.nvic = ''
      }
    }
  },
  created() {
    this.emtaVehicle = this.value
    this.getMakeList()
  },
  mounted() {},
  methods: {
    async getMakeList() {
      this.isLoadingMakeList = true
      const data = await EmtaService.getMakeList()
      let make = ''
      if (this.emtaVehicle.makeId) {
        make = data.find(m => m.makeID === this.emtaVehicle.makeId)
      } else if (this.make) {
        make = data.find(m => m.makeName === this.make)
      }
      this.emtaVehicle.makeId = make ? make.makeID : ''
      this.makeList = _orderBy(data, 'makeName')
      this.isLoadingMakeList = false
    },
    async getModelList() {
      this.isLoadingModelList = true
      const data = await EmtaService.getModelList(this.emtaVehicle.makeId, 0)
      let model = null
      if (this.emtaVehicle.modelId) {
        model = data.find(m => m.modelID === this.emtaVehicle.modelId)
      } else if (this.model) {
        const vm = this
        model = data.find(function(model) {
          return vm.model.toUpperCase().includes(model.modelName.toUpperCase())
        })
        this.emtaVehicle.modelId = model ? model.modelID : ''
      }
      this.modelList = _orderBy(data, 'modelName')
      this.isLoadingModelList = false
    },
    async getYearList() {
      this.isLoadingYearList = true
      const data = await EmtaService.getYearList(this.emtaVehicle.makeId, this.emtaVehicle.modelId)
      if (!this.emtaVehicle.year) {
        this.emtaVehicle.year = this.year
      }
      this.yearList = _orderBy(data)
      this.isLoadingYearList = false
    },
    async getModelVariantList() {
      this.isLoadingModelVariantList = true
      const data = await EmtaService.getModelVariantList(this.emtaVehicle.makeId, this.emtaVehicle.modelId, this.emtaVehicle.year)
      if (this.emtaVehicle.variantId) {
        const variant = data.find(v => v.variantId === this.emtaVehicle.variantId)
        this.selectedVariant = variant
      }
      this.modelVariantList = _orderBy(data)
      this.isLoadingModelVariantList = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/mixins/inputs/placeholder';

input.is-uppercase {
  text-transform: uppercase;
  @include reset-placeholder-text-transform;
}
</style>
