<template>
  <div>
    <div class="field">
      <label class="label">Company Name</label>
      <div class="control">
        <input 
          class="input is-capitalized"
          :class="{ 'is-danger' : !innerValue.businessName }"
          v-model="innerValue.businessName"
          type="text"
          placeholder="Company Name"
          v-focus-inserted>
      </div>
      <span 
        class="help is-danger"
        v-if="!innerValue.businessName">Company name is required.</span>
    </div>
    <div class="field">
      <label class="label">Contact Name</label>
      <div class="control">
        <input 
          class="input is-capitalized"
          v-model="innerValue.contactName"
          type="text"
          placeholder="Contact Name">
      </div>
    </div>
    <div class="field">
      <label class="label">Address</label>
      <div class="control">
        <input 
          class="input is-capitalized"
          v-model="innerValue.address1"
          type="text"
          placeholder="Address">
      </div>
    </div>
    <div class="field">
      <div class="control">
        <input 
          class="input is-capitalized"
          v-model="innerValue.address2"
          type="text"
          placeholder="">
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <label class="label">Suburb</label>
          <div class="control">
            <input 
              class="input is-capitalized"
              :class="{ 'is-danger' : !innerValue.city }"
              v-model="innerValue.city"
              type="text"
              placeholder="Suburb">
          </div>
          <span 
            class="help is-danger"
            v-if="!innerValue.city">Suburb is required.</span>
        </div>
        <div class="field">
          <label class="label">State</label>
          <div class="control">
            <input 
              class="input is-uppercase"
              :class="{ 'is-danger' : !innerValue.state }"
              v-model="innerValue.state"
              type="text"
              placeholder="State">
          </div>
          <span 
            class="help is-danger"
            v-if="!innerValue.state">State is required.</span>
        </div>
        <div class="field">
          <label class="label">Postcode</label>
          <div class="control">
            <input 
              class="input"
              :class="{ 'is-danger' : !innerValue.postalCode }"
              v-model="innerValue.postalCode"
              type="text"
              placeholder="Postcode">
          </div>
          <span 
            class="help is-danger"
            v-if="!innerValue.postalCode">Suburb is required.</span>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <label class="label">Phone 1</label>
          <div class="control">
            <input 
              class="input is-capitalized"
              v-model="innerValue.phone1"
              type="text"
              placeholder="Phone 1">
          </div>
        </div>
        <div class="field">
          <label class="label">Phone 2</label>
          <div class="control">
            <input 
              class="input is-capitalized"
              v-model="innerValue.phone2"
              type="text"
              placeholder="Phone 2">
          </div>
        </div>
        <div class="field">
          <label class="label">Email</label>
          <div class="control">
            <input 
              class="input"
              v-model="innerValue.email"
              type="text"
              placeholder="Email">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FocusInserted } from '@/components/directives'

export default {
  name: 'EmtaRegistration',
  directives: { FocusInserted },
  components: {},
  props: {
    value: null
  },
  data() {
    return {}
  },
  computed: {},
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    this.innerValue = this.value
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/mixins/inputs/placeholder';

input.is-uppercase {
  text-transform: uppercase;
  @include reset-placeholder-text-transform;
}
</style>
